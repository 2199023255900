@charset "utf-8";
@import "../../modules/_import";

//contact
.contactWrap {
  padding-bottom: 30px !important;
  &::before {
    left: 0;
    background-image: url(../common_img/bg-lt.png);
    background-position: left top;
  }
  .ttl-bullet--ud {
    margin-bottom: 0 !important;
  }
  .lnk-tel {
    font-size: 3.6rem;
    font-weight: 700;
    @include media(sm) {
      font-size: 6rem;
      font-weight: 500;
    }
    @include media(md) {
      font-size: 7.2rem;
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    @include media(sm) {
      font-size: 1.8rem;
    }
  }
}
//a1
.a1Wrap {
}
//pp
.ppWrap {
  background-color: rgba($color-b3, .15);
}