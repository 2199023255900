@charset "utf-8";
@import "../../modules/_import";

.page-about section [class*="Wrap"] {
  position: relative;
  @include media(md) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width:  100%;
      height: 100%;
      transform: skewX(-25deg);
    }
  }
}

//greeting
.greetingWrap{
  @include media(md) {
    background-color: $color-t2;
    background-image: url(../images/greeting-bg.jpg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 100%;
    &::before {
      right: -40%;
      background-color: #fff;
    }
  }
}
//sumamry
.summaryWrap {
  background-color: rgba($color-b3, .30);
  @include media(md) {
    &::before {
      left: -25%;
      background-color: #fff;
    }
    th {
      white-space: nowrap;
      width: 180px;
    }
  }
}
//access
.accessWrap {
  @include media(md) {
    &::before {
      left: -75%;
      background-color: rgba($color-b3, .30);
    }
  }
  iframe {
    width: 100%;
    height: 270px;
    border: 0 none;
    @include media(md) {
      height: 400px;
    }
  }
  dl {
    margin-top: $gutter / 2;
    @include media(md) {
      margin-top: 0;
    }
  }
  dt {
    border-bottom: 1px solid $color-t2;
    color: $color-t2;
    &::before {
      @include fontAwesome;
      margin-right: .5em;
    }
    &:nth-last-of-type(1)::before {
      content: "\f041";
    }
    &:nth-last-of-type(2)::before {
      content: "\f239";
    }
    &:nth-last-of-type(3)::before {
      content: "\f017";
    }
  }
  dd {
    margin-top: .5em;
    margin-bottom: 1em;
  }
}