@charset "utf-8";
@import "modules/_import";
/*! -----------------------------------------------
 * css content 
--------------------------------------------------
 * [object]
 * ------------------------------------------------
 * _project ページ固有のコンポーネント
 *
 * 001. home
 * 002. point
 * 003. service
 * 004. works
 * 005. about
 * 006. news
 * 007. inquiry
 *
 */

/* -----------------------------------------------
 * 001. home
-------------------------------------------------*/
@import "partials/content/_001-home";

/* -----------------------------------------------
 * 002. point
-------------------------------------------------*/
@import "partials/content/_002-point";

/* -----------------------------------------------
 * 003. service
-------------------------------------------------*/
@import "partials/content/_003-service";

/* -----------------------------------------------
 * 004. works
-------------------------------------------------*/
@import "partials/content/_004-works";

/* -----------------------------------------------
 * 005. about
-------------------------------------------------*/
@import "partials/content/_005-about";

/* -----------------------------------------------
 * 006. news
-------------------------------------------------*/
@import "partials/content/_006-news";

/* -----------------------------------------------
 * 007. inquiry
-------------------------------------------------*/
@import "partials/content/_007-inquiry";
