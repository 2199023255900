@charset "utf-8";
@import "../../modules/_import";

.pointWrap {
  .l-main section:not(:first-of-type) & {
    padding-top: 0;
  }
  .container {
    position: relative;
      &::before,
      &::after {
      content: "";
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      background-size: cover;
      backface-visibility: hidden;
    }
    &::before {
      top: 6px;
      z-index: 10;
    }
    &::after {
      top: 4px;
      z-index: 11;
    }
  }
  [class*="ttl-main--l"] {
    text-indent: -1.5em;
  }
  [class*="ttl-main--r"] {
    text-indent:  1.5em;
  }
  @include media(md) {
    .l-main section:not(:first-of-type) & {
      padding-top: $gutter;
    }
    .container {
      &::before,
      &::after {
        width:  120px;
        height: 120px;
      }
      &::before {
      top: 2px;
      }
      &::after {
      top: 6px;
      }
    }
    [class*="ttl-main--l"],
    [class*="ttl-main--r"] {
      text-indent: 0;
    }
  }
}

#point2,
#point4 {
  .row + .row {
    margin-top: $gutter / 2;
  }
  @include media(md) {
    .row + .row {
      margin-top: $gutter;
      align-items: center;
    }
    .row:nth-of-type(odd) {
      flex-direction: row-reverse;
    }
    .ttl-bullet--lt {
      margin-top: 0;
    }
  }
}
#point1 {
  .container::before { right: 10px; background-image: url(../common_img/ico-safety_grn.png); }
  .container::after  { right: 14px; background-image: url(../common_img/ico-safety.png); }
}
#point2 {
  .container::before { left:  10px; background-image: url(../common_img/ico-hearts_grn.png); }
  .container::after  { left:  14px; background-image: url(../common_img/ico-hearts.png); }
}
#point3 {
  .container::before { right: 10px; background-image: url(../common_img/ico-guard_grn.png); }
  .container::after  { right: 14px; background-image: url(../common_img/ico-guard.png); }
}
#point4 {
  .container::before { left:  10px; background-image: url(../common_img/ico-calc_grn.png); }
  .container::after  { left:  14px; background-image: url(../common_img/ico-calc.png); }
}