@charset "UTF-8";
/*! -----------------------------------------------
 * css content 
--------------------------------------------------
 * [object]
 * ------------------------------------------------
 * _project ページ固有のコンポーネント
 *
 * 001. home
 * 002. point
 * 003. service
 * 004. works
 * 005. about
 * 006. news
 * 007. inquiry
 *
 */
/* -----------------------------------------------
 * 001. home
-------------------------------------------------*/
/* loop */
.loop {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.loopSliderWrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.loopSlider {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  text-align: left;
}

.loopSlider ul {
  float: left;
  overflow: hidden;
}

.loopSlider ul li {
  float: left;
  display: inline;
  overflow: hidden;
}

.loop img {
  height: 100%;
}

@media (max-width: 575px) {
  .loop {
    top: 60px;
  }
  .loopSlider,
  .loopSlider ul li {
    height: 200px;
  }
}

@media (min-width: 576px) {
  .loop {
    top: 60px;
  }
  .loopSlider,
  .loopSlider ul li {
    height: 300px;
  }
}

@media (min-width: 768px) {
  .loop {
    top: 80px;
  }
}

/* mv */
.mvWrap {
  background-image: url(../images/mv_bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

.mvWrap-txt {
  margin-right: auto;
  margin-left: auto;
  padding: 15px 15px 30px;
  font-weight: bold;
  text-shadow: 2px 2px 1px #fff, -2px 2px 1px #fff, 2px -2px 1px #fff, -2px -2px 1px #fff;
}

@media (max-width: 767px) {
  .mvWrap-catch {
    padding-top: 200px;
    margin-right: auto;
    margin-left: auto;
  }
  .mvWrap-catch img {
    width: 100%;
    max-width: 720px;
  }
  .mvWrap-txt {
    font-size: 1.3rem;
  }
}

@media (max-width: 575px) {
  .mvWrap {
    background-size: auto 200px;
    background-position: right 60px;
  }
  .mvWrap-txt {
    font-size: 1.3rem;
  }
}

@media (min-width: 576px) {
  .mvWrap {
    background-size: auto 300px;
    background-position: right 60px;
  }
  .mvWrap-catch {
    padding-top: 285px;
  }
  .mvWrap-txt {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  .mvWrap {
    background-size: auto 600px;
    background-position: right bottom;
  }
  .mvWrap-catch {
    width: 100%;
    padding-top: 320px;
    margin-right: auto;
    margin-left: auto;
  }
  .mvWrap-catch img {
    width: 500px;
    max-width: 100%;
  }
  .mvWrap-txt {
    font-size: 2.4rem;
  }
}

@media (min-width: 992px) {
  .mvWrap {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    height: 800px;
    background-size: auto 720px;
    background-position: right bottom;
  }
  .mvWrap-catch {
    width: 100%;
    padding-top: 305px;
  }
  .mvWrap-catch img {
    width: 600px;
    max-width: 100%;
  }
  .mvWrap-txt {
    padding: 30px 15px;
    font-size: 2.4rem;
    font-weight: bold;
  }
}

@media (min-width: 1200px) {
  .mvWrap {
    background-size: auto 800px;
    background-position: right bottom;
  }
  .mvWrap-catch {
    width: 100%;
    padding-top: 275px;
  }
  .mvWrap-catch img {
    width: 720px;
    max-width: 100%;
  }
}

/* reason */
.hReasonWrap::before {
  left: 0;
  background-image: url(../common_img/bg-lt.png);
  background-position: left top;
}

.hReasonTxt {
  padding: 30px 0;
}

.hReasonTxt-ttl {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .hReasonTxt-ttl {
    text-align: center;
  }
  .hReasonTxt-ttl img {
    height: 62px;
  }
}

@media (min-width: 768px) {
  .hReasonTxt-ttl img {
    height: 93px;
  }
}

@media (min-width: 992px) {
  .hReasonTxt-ttl img {
    height: 124px;
  }
}

@media (max-width: 991px) {
  .hReason-items {
    padding-bottom: 45px;
  }
  .hReason-items img {
    width: 100%;
    backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .hReason-items {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .hReason-item {
    height: 100%;
  }
  .hReason-item:nth-of-type(1) a::before {
    background-image: url(../images/hReason-item1_lg.jpg);
  }
  .hReason-item:nth-of-type(2) a::before {
    background-image: url(../images/hReason-item2_lg.jpg);
  }
  .hReason-item:nth-of-type(3) a::before {
    background-image: url(../images/hReason-item3_lg.jpg);
  }
  .hReason-item:nth-of-type(4) a::before {
    background-image: url(../images/hReason-item4_lg.jpg);
  }
  .hReason-lnk__txt {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    text-align: center;
    background-color: #8fc31f;
  }
  .hReason-lnk__txt::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    opacity: .50;
    transform: scale(1);
    transition: opacity .3s, transform .3s;
  }
  .hReason-lnk__txt img {
    position: relative;
    z-index: 2;
  }
  .hReason-lnk__txt:hover::before {
    opacity: .90;
    transform: scale(1.1);
  }
}

/* Works */
.hWorksWrap::before {
  right: 0;
  background-image: url(../common_img/bg-rb.png);
  background-position: right bottom;
}

.hWorksTxt {
  padding: 45px 0 30px;
}

@media (max-width: 991px) {
  .hWorksTxt-ttl {
    margin-bottom: 7.5px;
    text-align: center;
  }
  .hWorksTxt-ttl img {
    height: 46.5px;
  }
}

@media (min-width: 992px) {
  .hWorksTxt-ttl {
    margin-bottom: 10px;
  }
  .hWorksTxt-ttl img {
    height: 62px;
  }
}

@media (min-width: 1200px) {
  .hWorksTxt-ttl {
    margin-bottom: 15px;
  }
  .hWorksTxt-ttl img {
    height: 93px;
  }
}

.hWorks-item {
  height: 100%;
}

.hWorks-item:nth-of-type(1) a::before {
  background-image: url(../images/hWorks-item1_bg.jpg);
}

.hWorks-item:nth-of-type(2) a::before {
  background-image: url(../images/hWorks-item2_bg.jpg);
}

.hWorks-item:nth-of-type(3) a::before {
  background-image: url(../images/hWorks-item3_bg.jpg);
}

.hWorks-lnk__txt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  text-align: center;
}

.hWorks-lnk__txt::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transform: scale(1);
  transition: opacity .3s, transform .3s;
}

.hWorks-lnk__txt::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../common_img/ico-arw__rb.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right bottom;
  transform: scale(1);
  transition: transform .3s;
  z-index: 5;
}

.hWorks-lnk__txt img {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 160px;
}

@media (max-width: 991px) {
  .hWorks-items {
    padding-bottom: 45px;
  }
  .hWorks-items img {
    width: 100%;
    backface-visibility: hidden;
  }
  .hWorks-lnk__txt {
    height: 240px;
  }
  .hWorks-lnk__txt::before {
    opacity: .65;
  }
}

@media (min-width: 992px) {
  .hWorks-items {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .hWorks-lnk__txt {
    height: 320px;
  }
  .hWorks-lnk__txt::before {
    opacity: .50;
  }
  .hWorks-lnk__txt:hover {
    z-index: 100;
  }
  .hWorks-lnk__txt:hover::before {
    opacity: .90;
    transform: scale(1.1);
  }
  .hWorks-lnk__txt:hover::after {
    transform: scale(1.1);
  }
}

/* service */
@media (max-width: 991px) {
  .hServiceWrap {
    padding: 45px 0;
  }
}

@media (min-width: 992px) {
  .hServiceWrap {
    padding: 90px 0;
  }
}

.hServiceWrap .ttl {
  text-align: center;
}

@media (max-width: 991px) {
  .hServiceWrap .ttl img {
    height: 49px;
  }
}

@media (min-width: 992px) {
  .hServiceWrap .ttl img {
    height: calc(98px * .75);
  }
}

@media (min-width: 1200px) {
  .hServiceWrap .ttl img {
    height: 98px;
  }
}

.hServiceWrap .ttl::after {
  content: "";
  display: block;
  width: 240px;
  height: 4px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  background-image: url();
  background-image: linear-gradient(-45deg, #232323 25%, #edb41e 25%, #edb41e 50%, #232323 50%, #232323 75%, #edb41e 75%, #edb41e);
  background-size: 20px 20px;
}

@media (max-width: 991px) {
  .hService-items {
    padding-top: 15px;
  }
  .hService-item {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (min-width: 992px) {
  .hService-items {
    padding-top: 30px;
  }
  .hService-item {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.hService-fig {
  position: relative;
}

@media (max-width: 991px) {
  .hService-fig {
    margin-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .hService-fig {
    padding-bottom: 30px;
  }
  .hService-fig:hover .hService-fig__img {
    transform: scale(1.05);
  }
}

@media (min-width: 1200px) {
  .hService-fig {
    padding-bottom: 60px;
  }
}

.hService-fig__img {
  width: 100%;
  transition: transform .3s;
}

.hService-fig__cap {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .hService-fig__cap {
    width: 42%;
  }
}

@media (min-width: 992px) {
  .hService-fig__cap {
    width: 90px;
  }
}

@media (min-width: 1200px) {
  .hService-fig__cap {
    width: 120px;
  }
}

.hService-fig__cap img {
  width: 100%;
}

/* under */
@media (max-width: 991px) {
  .hUnder {
    padding: 30px 0;
  }
}

@media (min-width: 992px) {
  .hUnder {
    padding: 60px 0;
  }
}

.hUnder-news {
  width: calc(100% - 350px);
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .hUnder-news {
    width: 100%;
  }
}

.hUnder-fb {
  width: 350px;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .hUnder-fb {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }
}

/* news */
@media (max-width: 991px) {
  .hNewsWrap {
    padding-bottom: 60px;
  }
}

.hNewsWrap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #dadada;
}

.hNewsWrap-header .ttl-line {
  margin-right: auto;
  margin-left: 0;
  color: #8fc31f;
}

.hNewsWrap .box-scroll {
  height: 270px;
  padding-top: 0 !important;
}

.hNewsWrap .tblist {
  display: block;
  line-height: 1.25;
}

.hNewsWrap .lst-tbl__desc + .lst-tbl__head {
  padding-top: 15px;
}

@media (min-width: 768px) {
  .hNewsWrap .tblist {
    display: flex;
    line-height: 1.5;
  }
  .hNewsWrap .lst-tbl__head {
    width: 90px;
    padding-top: 15px;
  }
  .hNewsWrap .lst-tbl__desc {
    width: calc(100% - 90px);
    padding-top: 15px;
    padding-left: 15px;
  }
}

/* fb-plugin */
.fb-plugin {
  width: 100%;
  max-width: 320px;
  height: 320px;
  margin-right: auto;
  margin-left: auto;
}

.fb-plugin iframe {
  width: 100%;
}

/* -----------------------------------------------
 * 002. point
-------------------------------------------------*/
.l-main section:not(:first-of-type) .pointWrap {
  padding-top: 0;
}

.pointWrap .container {
  position: relative;
}

.pointWrap .container::before, .pointWrap .container::after {
  content: "";
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: cover;
  backface-visibility: hidden;
}

.pointWrap .container::before {
  top: 6px;
  z-index: 10;
}

.pointWrap .container::after {
  top: 4px;
  z-index: 11;
}

.pointWrap [class*="ttl-main--l"] {
  text-indent: -1.5em;
}

.pointWrap [class*="ttl-main--r"] {
  text-indent: 1.5em;
}

@media (min-width: 768px) {
  .l-main section:not(:first-of-type) .pointWrap {
    padding-top: 30px;
  }
  .pointWrap .container::before, .pointWrap .container::after {
    width: 120px;
    height: 120px;
  }
  .pointWrap .container::before {
    top: 2px;
  }
  .pointWrap .container::after {
    top: 6px;
  }
  .pointWrap [class*="ttl-main--l"],
  .pointWrap [class*="ttl-main--r"] {
    text-indent: 0;
  }
}

#point2 .row + .row,
#point4 .row + .row {
  margin-top: 15px;
}

@media (min-width: 768px) {
  #point2 .row + .row,
  #point4 .row + .row {
    margin-top: 30px;
    align-items: center;
  }
  #point2 .row:nth-of-type(odd),
  #point4 .row:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
  #point2 .ttl-bullet--lt,
  #point4 .ttl-bullet--lt {
    margin-top: 0;
  }
}

#point1 .container::before {
  right: 10px;
  background-image: url(../common_img/ico-safety_grn.png);
}

#point1 .container::after {
  right: 14px;
  background-image: url(../common_img/ico-safety.png);
}

#point2 .container::before {
  left: 10px;
  background-image: url(../common_img/ico-hearts_grn.png);
}

#point2 .container::after {
  left: 14px;
  background-image: url(../common_img/ico-hearts.png);
}

#point3 .container::before {
  right: 10px;
  background-image: url(../common_img/ico-guard_grn.png);
}

#point3 .container::after {
  right: 14px;
  background-image: url(../common_img/ico-guard.png);
}

#point4 .container::before {
  left: 10px;
  background-image: url(../common_img/ico-calc_grn.png);
}

#point4 .container::after {
  left: 14px;
  background-image: url(../common_img/ico-calc.png);
}

/* -----------------------------------------------
 * 003. service
-------------------------------------------------*/
.serviceWrap {
  position: relative;
}

@media (min-width: 768px) {
  .serviceWrap::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
  }
  .serviceWrap .col-12:first-of-type {
    display: none;
  }
  .serviceWrap [class*="prg-"] {
    padding-bottom: 30px;
  }
}

.serviceWrap li {
  position: relative;
  padding-left: 1.2em;
}

.serviceWrap li::before {
  position: absolute;
  top: .5em;
  left: 0;
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-rendering: auto;
  line-height: 1;
  content: "\f046";
  color: #edb41e;
}

.serviceWrap .ttl-serv {
  position: relative;
  z-index: 11;
  margin-top: -25px;
  margin-bottom: 15px;
}

.serviceWrap .ttl-serv img {
  backface-visibility: hidden;
  height: 49.5px;
}

@media (min-width: 768px) {
  .serviceWrap .ttl-serv {
    margin-top: 30px;
  }
  .serviceWrap .ttl-serv img {
    height: 66px;
  }
}

#serv1, #serv2, #serv3, #serv4 {
  position: relative;
}

@media (min-width: 768px) {
  #serv1, #serv2, #serv3, #serv4 {
    background-color: rgba(238, 238, 238, 0.3);
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
  #serv1::before, #serv2::before, #serv3::before, #serv4::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #8fc31f;
  }
}

#serv1 .serviceWrap::before, #serv2 .serviceWrap::before, #serv3 .serviceWrap::before, #serv4 .serviceWrap::before {
  background-color: #fff;
}

#serv1, #serv3 {
  background-position: 100% top;
}

#serv1::before, #serv3::before {
  left: -38.5%;
  transform: skewX(25deg);
}

#serv1 .serviceWrap::before, #serv3 .serviceWrap::before {
  left: -40%;
  transform: skewX(25deg);
}

#serv2, #serv4 {
  background-position: left top;
}

#serv2::before, #serv4::before {
  right: -38.5%;
  transform: skewX(-25deg);
}

#serv2 .serviceWrap::before, #serv4 .serviceWrap::before {
  right: -40%;
  transform: skewX(-25deg);
}

@media (min-width: 768px) {
  #serv1 {
    background-image: url(../images/service-bg1.jpg);
  }
  #serv2 {
    background-image: url(../images/service-bg2.jpg);
  }
  #serv3 {
    background-image: url(../images/service-bg3.jpg);
  }
  #serv4 {
    background-image: url(../images/service-bg4.jpg);
  }
}

.priceWrap {
  position: relative;
  background-color: rgba(238, 238, 238, 0.3);
}

.priceWrap .row + .row {
  margin-bottom: 15px;
}

.priceWrap th, .priceWrap td {
  font-weight: 500;
}

.priceWrap thead th + th {
  text-align: center;
}

.priceWrap thead th:last-of-type {
  background-color: #edb41e;
}

.priceWrap tr td:last-of-type {
  font-weight: 700;
  background-color: #fefbf3;
}

.priceWrap tr td:last-of-type::before {
  color: #e0a712;
}

@media (min-width: 768px) {
  .priceWrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: -40%;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: skewX(-25deg);
  }
  .priceWrap td {
    text-align: center;
  }
  .priceWrap .row + .row {
    margin-bottom: 30px;
  }
}

/* -----------------------------------------------
 * 004. works
-------------------------------------------------*/
.l-main section:not(:first-of-type) .workWrap {
  padding-top: 0;
}

.workWrap .container {
  position: relative;
}

.workWrap .container::before, .workWrap .container::after {
  content: "";
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: cover;
}

.workWrap .container::before {
  top: 6px;
  z-index: 10;
}

.workWrap .container::after {
  top: 4px;
  z-index: 11;
}

.workWrap .row:last-of-type > * {
  margin-top: 30px;
}

.workWrap .row:last-of-type > * figure::after {
  display: block;
  position: absolute;
  bottom: -.5em;
  left: .5em;
  font-family: "Raleway", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #8fc31f;
  text-shadow: 2px 2px 1px #fff, -2px 2px 1px #fff, 2px -2px 1px #fff, -2px -2px 1px #fff;
}

.workWrap .row:last-of-type > *:nth-of-type(1) figure::after {
  content: "Before";
  color: #8fc31f;
}

.workWrap .row:last-of-type > *:nth-of-type(2) figure::after {
  content: "After";
  color: #edb41e;
}

.workWrap [class*="ttl-main--l"] {
  text-indent: -1.5em;
}

.workWrap [class*="ttl-main--r"] {
  text-indent: 1.5em;
}

.workWrap [class*="ttl-bullet"] {
  margin-bottom: 0 !important;
}

.workWrap figure {
  position: relative;
}

.workWrap figcaption {
  width: 100%;
  padding-top: .25em;
  padding-right: .25em;
  padding-left: 1em;
  font-weight: 700;
  line-height: 1.1;
  text-align: right;
  text-shadow: 2px 2px 0 #8fc31f;
  color: #fff;
}

@media (min-width: 768px) {
  .l-main section:not(:first-of-type) .workWrap {
    padding-top: 30px;
  }
  .workWrap .container::before, .workWrap .container::after {
    width: 108px;
    height: 108px;
  }
  .workWrap .mda-fig__sdw {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  .workWrap .mda-fig__sdw figcaption {
    font-size: 1.8rem;
  }
  .workWrap th {
    width: 72px;
  }
  .workWrap [class*="ttl-main--l"],
  .workWrap [class*="ttl-main--r"] {
    text-indent: 0;
  }
}

#work1 .container::before {
  right: 14px;
  background-image: url(../common_img/ico-store_grn.png);
}

#work1 .container::after {
  right: 16px;
  background-image: url(../common_img/ico-store.png);
}

#work2 .container::before {
  left: 16px;
  background-image: url(../common_img/ico-build_grn.png);
}

#work2 .container::after {
  left: 14px;
  background-image: url(../common_img/ico-build.png);
}

#work3 .container::before {
  right: 14px;
  background-image: url(../common_img/ico-house_grn.png);
}

#work3 .container::after {
  right: 16px;
  background-image: url(../common_img/ico-house.png);
}

@media (min-width: 768px) {
  #work3 + .gBnrs {
    margin-top: 60px;
  }
}

/* -----------------------------------------------
 * 005. about
-------------------------------------------------*/
.page-about section [class*="Wrap"] {
  position: relative;
}

@media (min-width: 768px) {
  .page-about section [class*="Wrap"]::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    transform: skewX(-25deg);
  }
}

@media (min-width: 768px) {
  .greetingWrap {
    background-color: #8fc31f;
    background-image: url(../images/greeting-bg.jpg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 100%;
  }
  .greetingWrap::before {
    right: -40%;
    background-color: #fff;
  }
}

.summaryWrap {
  background-color: rgba(238, 238, 238, 0.3);
}

@media (min-width: 768px) {
  .summaryWrap::before {
    left: -25%;
    background-color: #fff;
  }
  .summaryWrap th {
    white-space: nowrap;
    width: 180px;
  }
}

@media (min-width: 768px) {
  .accessWrap::before {
    left: -75%;
    background-color: rgba(238, 238, 238, 0.3);
  }
}

.accessWrap iframe {
  width: 100%;
  height: 270px;
  border: 0 none;
}

@media (min-width: 768px) {
  .accessWrap iframe {
    height: 400px;
  }
}

.accessWrap dl {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .accessWrap dl {
    margin-top: 0;
  }
}

.accessWrap dt {
  border-bottom: 1px solid #8fc31f;
  color: #8fc31f;
}

.accessWrap dt::before {
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-rendering: auto;
  line-height: 1;
  margin-right: .5em;
}

.accessWrap dt:nth-last-of-type(1)::before {
  content: "\f041";
}

.accessWrap dt:nth-last-of-type(2)::before {
  content: "\f239";
}

.accessWrap dt:nth-last-of-type(3)::before {
  content: "\f017";
}

.accessWrap dd {
  margin-top: .5em;
  margin-bottom: 1em;
}

/* -----------------------------------------------
 * 006. news
-------------------------------------------------*/
.page-news .col-12 .btns {
  margin-bottom: 30px;
  border-top: 1px dashed #dadada;
}

/* -----------------------------------------------
 * 007. inquiry
-------------------------------------------------*/
.contactWrap {
  padding-bottom: 30px !important;
}

.contactWrap::before {
  left: 0;
  background-image: url(../common_img/bg-lt.png);
  background-position: left top;
}

.contactWrap .ttl-bullet--ud {
  margin-bottom: 0 !important;
}

.contactWrap .lnk-tel {
  font-size: 3.6rem;
  font-weight: 700;
}

@media (min-width: 576px) {
  .contactWrap .lnk-tel {
    font-size: 6rem;
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .contactWrap .lnk-tel {
    font-size: 7.2rem;
  }
}

.contactWrap p {
  font-size: 1.6rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  .contactWrap p {
    font-size: 1.8rem;
  }
}

.ppWrap {
  background-color: rgba(238, 238, 238, 0.15);
}
