@charset "utf-8";
@import "../../modules/_import";

/* loop */
.loop {
  position: absolute;
  width: 100%;
  z-index: -1;
}
.loopSliderWrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.loopSlider {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  text-align: left;
}
.loopSlider ul {
  float: left;
  overflow: hidden;
}

.loopSlider ul li {
  float: left;
  display: inline;
  overflow: hidden;
}
.loop img {
  height: 100%;
}

@include media(xs) {
  .loop {
    top: 60px;
  }
  .loopSlider,
  .loopSlider ul li {
    height: 200px;
  }
}
@include media(sm) {
  .loop {
    top: 60px;
  }
  .loopSlider,
  .loopSlider ul li {
    height: 300px;
  }
}
@include media(md) {
  .loop {
    top: 80px;
  }
}
/* mv */
.mvWrap {
  background-image: url(../images/mv_bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  &-txt {
    margin-right: auto;
    margin-left:  auto;
    padding: 15px 15px 30px;
    font-weight: bold;
    text-shadow: 2px 2px 1px #fff,
                -2px 2px 1px #fff,
                 2px -2px 1px #fff,
                -2px -2px 1px #fff;
  }
}
@include media(sm-max) {
  .mvWrap {
    &-catch {
      padding-top: 200px;
      margin-right: auto;
      margin-left:  auto;
      img {
        width: 100%;
        max-width: 720px;
      }
    }
    &-txt {
      font-size: 1.3rem;
    }
  }
}
@include media(xs) {
  .mvWrap {
    background-size: auto 200px;
    background-position: right 60px;
    &-txt {
      font-size: 1.3rem;
    }
  }
}
@include media(sm) {
  .mvWrap {
    background-size: auto 300px;
    background-position: right 60px;
    &-catch {
      padding-top: 285px;
    }
    &-txt {
      font-size: 1.6rem;
    }
  }
}
@include media(md) {
  .mvWrap {
    background-size: auto 600px;
    background-position: right bottom;
    &-catch {
      width: 100%;
      padding-top: 320px;
      margin-right: auto;
      margin-left:  auto;
      img {
        width: 500px;
        max-width: 100%;
      }
    }
    &-txt {
      font-size: 2.4rem;
    }
  }
}
@include media(lg) {
  .mvWrap {
    max-width: 1440px;
    margin-right: auto;
    margin-left:  auto;
    height: 800px;
    background-size: auto 720px;
    background-position: right bottom;
    &-catch {
      width: 100%;
      padding-top: 305px;
      img {
        width: 600px;
        max-width: 100%;
      }
    }
    &-txt {
      padding: 30px 15px;
      font-size: 2.4rem;
      font-weight: bold;
    }
  }
}
@include media(xl) {
  .mvWrap {
    background-size: auto 800px;
    background-position: right bottom;
    &-catch {
      width: 100%;
      padding-top: 275px;
      img {
        width: 720px;
        max-width: 100%;
      }
    }
  }
}

/* reason */
.hReason {
  &Wrap {
    &::before {
      left: 0;
      background-image: url(../common_img/bg-lt.png);
      background-position: left top;
    }
  }
  &Txt {
    padding: $gutter 0;
    &-ttl {
      margin-bottom: $gutter / 2;
      @include media(md-max) {
        text-align: center;
        img {
          height: 62px;
        }
      }
      @include media(md) {
        img {
          height: 93px;
        }
      }
      @include media(lg) {
        img {
          height: 124px;
        }
      }
    }
  }
  @include media(md-max) {
    &-items {
      padding-bottom: 45px;
      img {
        width: 100%;
        backface-visibility: hidden;
      }
    }
  }
  @include media(lg) {
    &-items {
      height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &-item {
      height: 100%;
      &:nth-of-type(1) a::before { background-image: url(../images/hReason-item1_lg.jpg); }
      &:nth-of-type(2) a::before { background-image: url(../images/hReason-item2_lg.jpg); }
      &:nth-of-type(3) a::before { background-image: url(../images/hReason-item3_lg.jpg); }
      &:nth-of-type(4) a::before { background-image: url(../images/hReason-item4_lg.jpg); }
    }
    &-lnk__txt {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 5px;
      text-align: center;
      background-color: $color-t2;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 0 0 5px rgba(#000, .30);
        opacity: .50;
        transform: scale(1);
        transition: opacity .3s, transform .3s;
      }
      img {
        position: relative;
        z-index: 2;
      }
      &:hover::before {
        opacity: .90;
        transform: scale(1.1);
      }
    }
  }
  
}


/* Works */
.hWorks {
  &Wrap {
    &::before {
      right: 0;
      background-image: url(../common_img/bg-rb.png);
      background-position: right bottom;
    }
  }
  &Txt {
    padding: ($gutter * 1.5) 0 $gutter;
    &-ttl {
      @include media(md-max) {
        margin-bottom: $gutter / 4;
        text-align: center;
        img {
          height: 46.5px;
        }
      }
      @include media(lg) {
        margin-bottom: $gutter / 3;
        img {
          height: 62px;
        }
      }
      @include media(xl) {
        margin-bottom: $gutter / 2;
        img {
          height: 93px;
        }
      }
    }
  }
    &-item {
      height: 100%;
      &:nth-of-type(1) a::before { background-image: url(../images/hWorks-item1_bg.jpg); }
      &:nth-of-type(2) a::before { background-image: url(../images/hWorks-item2_bg.jpg); }
      &:nth-of-type(3) a::before { background-image: url(../images/hWorks-item3_bg.jpg); }
    }
    &-lnk__txt {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      text-align: center;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 0 0 5px rgba(#000, .30);
        transform: scale(1);
        transition: opacity .3s, transform .3s;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../common_img/ico-arw__rb.png);
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: right bottom;
        transform: scale(1);
        transition: transform .3s;
        
        z-index: 5;
      }
      img {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 160px;
      }
  }
  @include media(md-max) {
    &-items {
      padding-bottom: 45px;
      img {
        width: 100%;
        backface-visibility: hidden;
      }
    }
    &-lnk__txt {
      height: 240px;
      &::before {
        opacity: .65;
      }
    }
  }
  @include media(lg) {
    &-items {
      height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &-lnk__txt {
      height: 320px;
      &::before {
        opacity: .50;
      }
      &:hover {
        z-index: 100;
      }
      &:hover::before {
        opacity: .90;
        transform: scale(1.1);
      }
      &:hover::after {
        transform: scale(1.1);
      }
    }
  }
}

/* service */
.hService {
  &Wrap {
    @include media(md-max) {
      padding: ($gutter * 1.5) 0;
    }
    @include media(lg) {
      padding: ($gutter * 3) 0;
    }
    .ttl {
      text-align: center;
      @include media(md-max) {
        img {
          height: 49px;
        }
      }
      @include media(lg) {
        img {
          height: calc(98px * .75);
        }
      }
      @include media(xl) {
        img {
          height: 98px;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 240px;
        height: 4px;
        margin-top: 15px;
        margin-right: auto;
        margin-left:  auto;
        background-image: url();
        background-image: linear-gradient(
          -45deg,
          $color-b1 25%, $color-t5 25%,
          $color-t5 50%, $color-b1 50%,
          $color-b1 75%, $color-t5 75%,
          $color-t5
        );
        background-size: 20px 20px;
      }
    }
  }
  @include media(md-max) {
    &-items {
      padding-top: $gutter / 2;
    }
    &-item {
      padding-right: 5px !important;
      padding-left:  5px !important;
    }
  }
  @include media(lg) {
    &-items {
      padding-top: $gutter;
    }
    &-item {
      padding-right: $gutter / 2 !important;
      padding-left:  $gutter / 2 !important;
    }
  }
  &-fig {
    position: relative;
    @include media(md-max) {
      margin-top: $gutter / 2;
      padding-bottom: $gutter / 2;
    }
    @include media(lg) {
      padding-bottom: $gutter;
      &:hover .hService-fig__img {
        transform: scale(1.05);
      }
    }
    @include media(xl) {
      padding-bottom: $gutter * 2;
    }
    &__img {
      width: 100%;
      transition: transform .3s;
    }
    &__cap {
      position: absolute;
      right: 0;
      bottom: 0;
      @include media(md-max) {
        width: 42%;
      }
      @include media(lg) {
        width: 90px;
      }
      @include media(xl) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }
  }
}

/* under */
.hUnder {
  @include media(md-max) {
    padding: $gutter 0;
  }
  @include media(lg) {
    padding: ($gutter * 2) 0;
  }
  &-news {
    width: calc(100% - 350px);
    padding-right: $gutter / 2;
    padding-left:  $gutter / 2;
    @include media(md-max) {
      width: 100%;
    }
  }
  &-fb {
    width: 350px;
    max-width: 100%;
    padding-right: $gutter / 2;
    padding-left:  $gutter / 2;
    @include media(md-max) {
      margin-right: auto;
      margin-left:  auto;
      padding-right: 0;
      padding-left:  0;
    }
  }
}

/* news */
.hNews {
  &Wrap {
    @include media(md-max){
      padding-bottom: $gutter * 2;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-b2;
      .ttl-line {
        margin-right: auto;
        margin-left:  0;
        color: $color-t2;
      }
    }
    .box-scroll {
      height: 270px;
      padding-top: 0 !important;
    }
    .tblist {
      display: block;
      line-height: 1.25;
    }
    .lst-tbl__desc + .lst-tbl__head {
      padding-top: 15px;
    }
    @include media(md){
      .tblist {
        display: flex;
        line-height:  1.5;
      }
      .lst-tbl__head {
        width: 90px;
        padding-top: 15px;
      }
      .lst-tbl__desc {
        width: calc(100% - 90px);
        padding-top:  15px;
        padding-left: 15px;
      }
    }
  }
}

/* fb-plugin */
.fb-plugin {
  width: 100%;
  max-width: 320px;
  height: 320px;
  margin-right: auto;
  margin-left:  auto;
  iframe {
    width: 100%;
  }
}
