@charset "utf-8";
@import "../../modules/_import";

.serviceWrap {
  position: relative;
  @include media(md) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width:  100%;
      height: 100%;
    }
    .col-12:first-of-type {
      display: none;
    }
    [class*="prg-"] {
      padding-bottom: $gutter;
    }
  }
  li {
    position: relative;
    padding-left: 1.2em;
    &::before {
      position: absolute;
      top:  .5em;
      left: 0;
      @include fontAwesome;
      content: "\f046";
      color: $color-t5;
    }
  }
  .ttl-serv {
    position: relative;
    z-index: 11;
    margin-top: -25px;
    margin-bottom: $gutter / 2;
    img {
      backface-visibility: hidden;
      height: 49.5px;
    }
    @include media(md) {
      margin-top: $gutter;
      img {
        height: 66px;
      }
    }
  }
}
#serv1, #serv2, #serv3, #serv4 {
  position: relative;
  @include media(md) {
    background-color: rgba($color-b3, .30);
    background-repeat: no-repeat;
    background-size: auto 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width:  100%;
      height: 100%;
      background-color: $color-t2;
    }
  }
  .serviceWrap::before {
    background-color: #fff;
  }
}
#serv1, #serv3 {
  background-position: 100% top;
  &::before {
    left:  -38.5%;
    transform: skewX(25deg);
  }
  .serviceWrap::before {
    left:  -40%;
    transform: skewX(25deg);
  }
}
#serv2,#serv4 {
  background-position: left top;
  &::before {
    right: -38.5%;
    transform: skewX(-25deg);
  }
  .serviceWrap::before {
    right: -40%;
    transform: skewX(-25deg);
  }
}
@include media(md) {
  #serv1 { background-image: url(../images/service-bg1.jpg); }
  #serv2 { background-image: url(../images/service-bg2.jpg); }
  #serv3 { background-image: url(../images/service-bg3.jpg); }
  #serv4 { background-image: url(../images/service-bg4.jpg); }
}

//price
.priceWrap {
  position: relative;
  background-color: rgba($color-b3, .30);
  .row + .row {
    margin-bottom: $gutter / 2;
  }
  th, td {
    font-weight: 500;
  }
  thead th + th {
    text-align: center;
  }
  thead th:last-of-type {
    background-color: $color-t5;
  }
  tr td:last-of-type {
    font-weight: 700;
    background-color: lighten($color-t5, 45%);
    &::before {
      color: darken($color-t5, 5%);
    }
  }
  @include media(md) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -40%;
      bottom: 0;
      margin: auto;
      display: block;
      width:  100%;
      height: 100%;
      background-color: #fff;
      transform: skewX(-25deg);
    }
    td {
      text-align: center;
    }
    .row + .row {
      margin-bottom: $gutter;
    }
  }
}
