@charset "utf-8";
@import "../../modules/_import";

.workWrap {
  .l-main section:not(:first-of-type) & {
    padding-top: 0;
  }
  .container {
    position: relative;
      &::before,
      &::after {
      content: "";
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      background-size: cover;
    }
    &::before {
      top:   6px;
      z-index: 10;
    }
    &::after {
      top:   4px;
      z-index: 11;
    }
  }
  .row:last-of-type > * {
    margin-top: $gutter;
    figure::after {
      display: block;
      position: absolute;
      bottom: -.5em;
      left: .5em;
      font-family: $ff-rale;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      color: $color-t2;
      text-shadow: 2px 2px 1px #fff, -2px 2px 1px #fff, 2px -2px 1px #fff, -2px -2px 1px #fff;
    }
    &:nth-of-type(1) figure::after {
      content: "Before";
      color: $color-t2;
    }
    &:nth-of-type(2) figure::after {
      content: "After";
      color: $color-t5;
    }
  }
  [class*="ttl-main--l"] {
    text-indent: -1.5em;
  }
  [class*="ttl-main--r"] {
    text-indent:  1.5em;
  }
  [class*="ttl-bullet"] {
    margin-bottom: 0 !important;
  }
  figure {
    position: relative;
  }
  figcaption {
    width: 100%;
    padding-top:   .25em;
    padding-right: .25em;
    padding-left:  1em;
    font-weight: 700;
    line-height: 1.1;
    text-align: right;
    text-shadow: 2px 2px 0 $color-t2;
    color: #fff;
  }
  @include media(md) {
    .l-main section:not(:first-of-type) & {
      padding-top: $gutter;
    }
    .container {
      &::before,
      &::after {
        width:  108px;
        height: 108px;
      }
    }
    .mda-fig__sdw {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      figcaption {
        font-size: 1.8rem;
      }
    }
    th {
      width: 72px;
    }
    [class*="ttl-main--l"],
    [class*="ttl-main--r"] {
      text-indent: 0;
    }
  }
}
#work1 {
  .container::before { right: 14px; background-image: url(../common_img/ico-store_grn.png); }
  .container::after  { right: 16px; background-image: url(../common_img/ico-store.png); }
}
#work2 {
  .container::before { left:  16px; background-image: url(../common_img/ico-build_grn.png); }
  .container::after  { left:  14px; background-image: url(../common_img/ico-build.png); }
}
#work3 {
  .container::before { right: 14px; background-image: url(../common_img/ico-house_grn.png); }
  .container::after  { right: 16px; background-image: url(../common_img/ico-house.png); }
  @include media(md) {
    & + .gBnrs {
      margin-top: $gutter * 2;
    }
  }
}